import { useState } from "react"
import { latencyStrings } from "@/types/AppMessage"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { sendSaveSettings } from "@/types/Device"
import { Agent } from "@/services/BonzaService"
import { checkOKToStart } from "../SelectMenu"
import SelectInput from "../formInputs/SelectInput"

export default function LatencySelector(props: SelectorExtraProps) {
    const [value, setValue] = useState(
        props.value ??
            props.device.deviceDataStore.getSavedSettings().frameSizeIndex
    )

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index: number = JSON.parse(event.target.value)
        setValue(index)

        const settings = props.device.getSavedSettings()
        if (settings.frameSizeIndex != `${index}`) {
            settings.frameSizeIndex = `${index}`
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange) props.handleChange(event)
    }

    return (
        <SelectInput
            value={`${value ?? "NONE"}`}
            onChange={handleChange}
            disabled={props.disabled}
        >
            <option key={"None"} value={"NONE"}>
                None
            </option>
            {latencyStrings.map((count, index) => (
                <option key={index} value={index}>
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
