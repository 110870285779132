import { PropsWithChildren } from "react"
//import VuMeter from "@/components/VuMeter";
import { WebSocketService } from "@/types/WebSocket"
import LocalControl, {
    LR_Type,
    LocalControlType,
    ControlStyleSK,
    minMax2Float01,
    KnobMinMax,
} from "@/components/AudioControlLocal"
import { IAgentService } from "@/services/AgentService"
import { SetMasterPanV240124Message } from "@/types/AppMessage"
import VuMeterDouble from "./VuMeterDouble"
import SelectInput from "./SelectRefInput"
import { Agent } from "@/services/BonzaService"

export function AudioPanelMaster({
    children,
    ...props
}: PropsWithChildren<{
    channel: number
    agent: WebSocketService & IAgentService
    className?: string
}>) {
    const channel = props.channel
    const agent = props.agent

    const LocalControlPanKnobChangeListener = (newval: number) => {
        // SetLocalPanV240124Message expects number range 0:1 as float
        const val01 = minMax2Float01(newval, new KnobMinMax())
        agent.send(new SetMasterPanV240124Message(val01))
        Agent.setLocalPanForChannel(channel, newval)
    }

    return (
        <div className={`flex flex-col`}>
            <div className={`-mt-2 mb-2 flex flex-row gap-1 space-x-4`}>
                <VuMeterDouble
                    lrtype={LR_Type.Local}
                    channel={channel}
                    agent={agent}
                    ticks={true}
                    vals={true}
                    columns={2}
                    className={`
                        h-96 items-center text-center

                        dark:text-gray-200
                    `}
                />
                <div
                    className={`
                        flex grow items-center justify-center rounded-xl
                        bg-bonza-dark
                    `}
                >
                    <LocalControl
                        type={LocalControlType.Volume}
                        styleSK={ControlStyleSK.Slider}
                        channel={channel}
                        agent={agent}
                        className={`
                            h-96 items-center text-center

                            dark:text-gray-200
                        `}
                    />
                </div>
            </div>
            <div
                className={`
                    mb-2 flex flex-row items-center justify-center space-x-2
                    rounded-xl bg-bonza-dark py-4
                `}
            >
                <span className="text-lg text-bonza-pale">L</span>
                {/* HACK: The Knob component is super weird with its backgrounds and absolute positioning so I'm just setting a fixed w/h that looks okay and positioning off that (this would take a whole day in itself to fix)*/}
                <div
                    className={`
                        h-[110px] w-[120px] rounded-xl border-2 border-solid
                        border-bonza-dark bg-bonza-dark-semi
                    `}
                >
                    <LocalControl
                        type={LocalControlType.Pan}
                        styleSK={ControlStyleSK.Knob}
                        channel={channel}
                        agent={agent}
                        className="mr-3 flex-1 scale-75"
                        listener={LocalControlPanKnobChangeListener}
                    />
                </div>
                <span className="text-lg text-bonza-pale">R</span>
            </div>
        </div>
    )
}
