import { useState } from "react"
import {
    defaultOutputChannelsIndex,
    outputChannelCountPossibilities,
    outputChannelCountPossibilitiesReverseMap,
} from "@/types/AppMessage"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { fNOP, sendSaveSettings } from "@/types/Device"
import { checkOKToStart } from "../SelectMenu"
import SelectInput from "../formInputs/SelectInput"

function getValidOutputChannelIndex(outChannelsActual: string): number {
    const TEST_DELME: boolean = false
    if (TEST_DELME) {
        const testresults = new Array(19)
        for (let i = 0; i <= 16; i++) {
            testresults[i] = outputChannelCountPossibilitiesReverseMap[i]
        }
        testresults[17] = outputChannelCountPossibilitiesReverseMap[-1]
        testresults[18] = outputChannelCountPossibilitiesReverseMap[NaN]
        // both are undefined
        fNOP() // bpt here to check
    }
    const tmp =
        outputChannelCountPossibilitiesReverseMap[Number(outChannelsActual)]
    return tmp ?? defaultOutputChannelsIndex
}

export default function OutputChannelsSelector(props: SelectorExtraProps) {
    const derivedIndex: number = getValidOutputChannelIndex(
        props.device.getSavedSettings().outChannelsActual
    )
    const [value, setValue] = useState(props.value ?? derivedIndex)

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const index: number = JSON.parse(event.target.value)
        setValue(index)

        //props.device.deviceDataStore.outChans = outputChannelCountPossibilities[index];
        //props.device.deviceDataStore.outChansSelectIndex = index;

        const tmp = `${outputChannelCountPossibilities[index]}`
        const settings = props.device.getSavedSettings()
        if (settings.outChannelsActual != tmp) {
            settings.outChannelsActual = tmp
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange) props.handleChange(event)
    }

    function calcDisabledState(index: number): boolean {
        const dev = LocalDevice
        const outcard = dev.activeOutputSoundCard
        if (!outcard) {
            return true
        }
        const maxchans = outcard?.outputChannels
        const thisnc = outputChannelCountPossibilities[index]
        if (thisnc > maxchans) {
            return true
        }
        return false
    }

    function LimitValue(inval: number | string | readonly string[]): string {
        const dev = LocalDevice
        const outcard = dev.activeOutputSoundCard
        if (!outcard) {
            return "0"
        }
        const maxchans = outcard.outputChannels
        let valn = inval instanceof Array ? inval[0] : inval
        valn = Number(valn)
        while (valn > 0) {
            const thisnc = outputChannelCountPossibilities[valn]
            if (thisnc > maxchans) {
                --valn
                setValue(valn)
            } else {
                break
            }
        }
        return valn.toString()
    }

    return (
        <SelectInput
            value={`${LimitValue(value)}`}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {outputChannelCountPossibilities.map((count, index) => (
                <option
                    key={index}
                    value={index}
                    disabled={calcDisabledState(index)}
                >
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
