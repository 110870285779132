import { SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { diffuseIRDepthMessageV240124 } from "@/types/AppMessage"
import { sendSaveSettings } from "@/types/Device"
import SelectInput from "../formInputs/SelectInput"

export default function DiffuseIRDepthSelector(props: SelectorProps) {
    const [depthIndex, setDepthIndex] = useState(
        parseInt(LocalDevice.getSavedSettings().diffuseIRLevelIndex ?? "0")
    )

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const depthIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.diffuseIRLevelIndex != `${depthIndex}`) {
            settings.diffuseIRLevelIndex = `${depthIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new diffuseIRDepthMessageV240124(depthIndex))
            sendSaveSettings(Agent, settings)
        }
        setDepthIndex(depthIndex)

        if (props.handleChange) props.handleChange(event)
    }

    const values = []
    for (let i = 0; i < 5; i++) {
        values.push(
            <option value={i} key={i}>
                {i == 0 ? "Diffuse IR off" : `Diffuse IR depth ${i}`}
            </option>
        )
    }
    values.push(
        <option value={5} key={5}>
            Mute locals
        </option>
    )

    return (
        <SelectInput
            value={`${depthIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {values}
        </SelectInput>
    )
}
