import { SelectChangeEvent } from "@mui/material"
import PrimaryButton from "@/components/Button"
import { Agent, LocalDevice, LocalRemoteManager } from "@/services/BonzaService"
import { useEffect, useRef, useState } from "react"
import { SoundCardIOType } from "@/types/DeviceSelector"
import VideoDeviceSelector from "@/components/settingsSelectors/VideoDeviceSelector"
import {
    Construction as SettingsIcon,
    ExitToApp as InputIcon,
    Output as OutputIcon,
    Videocam as VideoIcon,
} from "@mui/icons-material"
import { LR_Type } from "@/components/AudioControlLocal"
import VuMeterMini from "@/components/VuMeterMini"
import { ReadyState } from "@/types/WebSocket"
import { fNOP, IDevice } from "@/types/Device"
import InputSoundCardSelector from "@/components/settingsSelectors/InputSoundCardSelector"
import OutputSoundCardSelector from "@/components/settingsSelectors/OutputSoundCardSelector"
import InputChannelsSelector from "@/components/settingsSelectors/InputChannelsSelector"
import OutputChannelsSelector from "@/components/settingsSelectors/OutputChannelsSelector"
import LatencySelector from "@/components/settingsSelectors/LatencySelector"
import {
    CodecOptionStrings,
    inputChannelCountPossibilities,
    NetworkBufferSizeStrings,
    openASIOPanelMessage,
    playTestSoundMessage,
    playTestSoundMode,
    SampleRateValueStrings,
    setStreamQualityMessage,
} from "@/types/AppMessage"

import DiffuseIRDepthSelector from "@/components/settingsSelectors/DiffuseIRDepthSelector"
import TwoChannelZoneSelector from "@/components/settingsSelectors/TwoChannelZoneSelector"
import DirectAudioCheckbox from "@/components/checkboxes/DirectAudioCheckbox"
import VideoColorSelector from "../settingsSelectors/VideoColourSelector"
import VideoResolutionSelector from "../settingsSelectors/VideoResolutionSelector"
import VideoDisplay from "@/components/VideoDisplay"
import GenericSelector from "@/components/settingsSelectors/GenericSelector"
import { useBonzaContext } from "@/context/BonzaContext"
import Checkbox from "../Checkbox"
import Dialog from "../Dialog"
import { NavBarButton } from "../NavButton"
import Plus from "../icons/plus"
import Video from "../icons/video"

type SettingsDialogProps = {
    show: boolean
    closeCallback: () => void
}

type SettingsMode = "input" | "output" | "advanced" | "video"

export default function SettingsDialog(props: SettingsDialogProps) {
    const {
        screenSharing,
        toggleScreenSharing,
        setInputChannelCount,
        locals,
        developerMode,
        setDeveloperMode,
    } = useBonzaContext()

    const [mode, setMode] = useState<SettingsMode>("input")

    const [showAdvanced, setShowAdvanced] = useState(false)

    const [allowSelect, setAllowSelect] = useState(
        Agent.readyState == ReadyState.Open
    )
    const [inputDevice, setInputDevice] = useState(
        LocalDevice.activeInputSoundCard
    )
    const [outputDevice, setOutputDevice] = useState(
        LocalDevice.activeOutputSoundCard
    )
    const [videoDevice, setVideoDevice] = useState(
        LocalDevice.activeVideoDevice
    )

    const { ...domProps } = props
    const isMounted = useRef(true)

    function asioClickHandler() {
        const os: string | null = LocalRemoteManager.workingData.selfInfo.OS
        if (os == null || os == "MAC OSX") {
            return // Mac doesnt use asio
        }
        if (
            LocalDevice.activeInputSoundCard &&
            LocalDevice.activeOutputSoundCard
        ) {
            const indexin: number = LocalDevice.activeInputSoundCard.index
            const indexout: number = LocalDevice.activeOutputSoundCard.index
            Agent.send(new openASIOPanelMessage(indexin, indexout))
        }
    }

    const handleSelectChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | SelectChangeEvent
            | {
                  target: {
                      name: string
                      value: number | string | readonly string[]
                  }
              }
    ) => {
        switch (event.target.name) {
            case "InputChannelsSelector":
                if (locals.length) {
                    //const count = inputChannelCountPossibilities[LocalDevice.deviceDataStore.inChansSelectIndex];
                    const count =
                        inputChannelCountPossibilities[
                            Number(
                                LocalDevice.getSavedSettings().inChannelsIndex
                            )
                        ]
                    setInputChannelCount(count)
                }
                break
            case "OutputChannelsSelector":
                break
            case "VideoResolutionSelector":
            case "VideoColourSelector": {
                const settings = LocalDevice.getSavedSettings()
                if (event.target.name == "VideoResolutionSelector") {
                    settings.videoResolutionIndex = `${event.target.value}`
                } else {
                    settings.videoColorIndex = `${event.target.value}`
                }
                LocalDevice.saveSavedSettings(settings)
                break
            }
        }
    }

    useEffect(() => {
        isMounted.current = true

        const deviceChangeListener = {
            handleDeviceChange: (device: IDevice) => {
                setInputDevice(device.activeInputSoundCard)
                setOutputDevice(device.activeOutputSoundCard)
                setVideoDevice(device.activeVideoDevice)
            },
        }

        const agentListener = {
            handleSocketEvent: () => {
                setAllowSelect(Agent.readyState == ReadyState.Open)
            },
        }

        LocalDevice.addDeviceChangeListener(deviceChangeListener)
        Agent.addEventListener(agentListener)
        return () => {
            isMounted.current = false
            LocalDevice.removeDeviceChangeListener(deviceChangeListener)
            Agent.removeEventListener(agentListener)
        }
    }, [])

    return (
        <Dialog
            show={props.show}
            aria-labelledby={"local-device-dialog-title"}
            closeable
            onClose={props.closeCallback}
        >
            <div
                className={`
                    flex max-h-fit flex-row items-stretch transition-all
                    duration-100 ease-in-out
                `}
            >
                <div
                    className={`
                        flex w-[215px] flex-col gap-6 rounded-l-3xl
                        rounded-r-none bg-bonza-dark p-6
                    `}
                >
                    <NavBarButton
                        label="Input"
                        active={mode == "input"}
                        onClick={() => setMode("input")}
                    >
                        <InputIcon className="rotate-180" />
                    </NavBarButton>
                    <NavBarButton
                        label="Output"
                        active={mode == "output"}
                        onClick={() => setMode("output")}
                    >
                        <OutputIcon />
                    </NavBarButton>
                    <NavBarButton
                        label="Video"
                        active={mode == "video"}
                        onClick={() => setMode("video")}
                    >
                        <Video />
                    </NavBarButton>
                    <NavBarButton
                        label="Advanced"
                        active={mode == "advanced"}
                        onClick={() => setMode("advanced")}
                    >
                        <SettingsIcon />
                    </NavBarButton>
                </div>

                <div
                    className={`
                        flex flex-grow flex-col items-stretch gap-2
                        overflow-y-auto rounded-l-none rounded-r-3xl
                        bg-bonza-dark-semi p-6
                    `}
                >
                    <div
                        className={`flex flex-row items-center justify-between`}
                        id="local-device-dialog-title"
                    >
                        <h1 className={`text-lg capitalize text-bonza-pale`}>
                            {mode}
                        </h1>
                        <button
                            onClick={() => props.closeCallback()}
                            className={`
                                relative rounded-full bg-bonza-dark-semi p-1
                                text-bonza-pale ring-0 transition duration-100
                                ease-in-out

                                hover:ring hover:ring-bonza-primary
                            `}
                        >
                            <Plus className="h-4 w-4 rotate-45" />
                        </button>
                    </div>
                    <div className={`min-w-[500px] text-bonza-pale`}>
                        {
                            {
                                input: <InputMode />,
                                output: <OutputMode />,
                                advanced: <AdvancedMode />,
                                video: <VideoMode />,
                            }[mode]
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    )

    function InputMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] items-center gap-y-4
                        pt-4
                    `}
                >
                    <label
                        className={`
                            text-md

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Input device
                    </label>
                    <InputSoundCardSelector
                        disabled={!allowSelect}
                        title="Input Card"
                        device={LocalDevice}
                        type={SoundCardIOType.Input}
                    />

                    <label
                        className={`
                            text-md

                            ${inputDevice == null ? `text-bonza-grey` : ""}
                        `}
                    >
                        Input channels
                    </label>
                    <InputChannelsSelector
                        disabled={inputDevice == null}
                        agent={Agent}
                        device={LocalDevice}
                        handleChange={handleSelectChange}
                        name="InputChannelsSelector"
                    />

                    <label
                        className={`
                            text-md

                            ${inputDevice == null ? `text-bonza-grey` : ""}
                        `}
                    >
                        Latency
                    </label>
                    <LatencySelector
                        disabled={inputDevice == null}
                        agent={Agent}
                        device={LocalDevice}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <label
                    className={`
                        text-md

                        ${inputDevice == null ? `text-bonza-grey` : ""}
                    `}
                >
                    Test your input
                </label>
                <VuMeterMini
                    inactive={false}
                    invert={false}
                    lrtype={LR_Type.Local}
                    columns={2}
                    agent={Agent}
                    channel={0}
                    className={`-ml-[2px] h-[30px] w-[435px]`}
                />
            </>
        )
    }

    function OutputMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] items-center gap-y-4
                        pt-4
                    `}
                >
                    <label
                        className={`
                            text-md

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Output device
                    </label>

                    <OutputSoundCardSelector
                        disabled={!allowSelect}
                        title="Output Card"
                        device={LocalDevice}
                        type={SoundCardIOType.Output}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <label
                    className={`
                        text-md

                        ${outputDevice == null ? `text-bonza-grey` : ""}
                    `}
                >
                    Test your output
                </label>
                <VuMeterMini
                    inactive={true}
                    invert={false}
                    lrtype={LR_Type.Local}
                    columns={2}
                    agent={Agent}
                    className={`-ml-[2px] mb-3 h-[30px] w-[435px]`}
                />
                <PrimaryButton
                    disabled={outputDevice == null}
                    onClick={() =>
                        Agent.send(
                            new playTestSoundMessage(
                                1.5,
                                playTestSoundMode.left_then_right
                            )
                        )
                    }
                >
                    Play sound
                </PrimaryButton>
            </>
        )
    }

    function VideoMode() {
        return (
            <>
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] items-center gap-y-4
                        pt-4
                    `}
                >
                    <label
                        className={`
                            text-md

                            ${!allowSelect ? `text-bonza-grey` : ""}
                        `}
                    >
                        Camera
                    </label>
                    <VideoDeviceSelector
                        disabled={!allowSelect}
                        device={LocalDevice}
                        handleChange={handleSelectChange}
                    ></VideoDeviceSelector>
                    {/* Rez */}
                    <label className={`text-md`}>Resolution</label>
                    <VideoResolutionSelector
                        handleChange={handleSelectChange}
                    />
                    {/* BW */}
                    <label className={`text-md`}>Colour Mode</label>
                    <VideoColorSelector handleChange={handleSelectChange} />
                    {/* Screen sharing */}
                    <label className={`text-md`}>Screen Sharing</label>
                    <Checkbox
                        checked={screenSharing}
                        onChange={toggleScreenSharing}
                    />
                </div>
                <hr className="my-8 border-bonza-grey" />
                <VideoDisplay
                    {...domProps}
                    id={-1}
                    className={`
                        aspect-video w-full overflow-hidden rounded-3xl border
                        border-bonza-dark bg-bonza-dark bg-cover bg-center p-2
                    `}
                    style={videoDevice ? {} : { opacity: 0.5 }}
                />
            </>
        )
    }

    function AdvancedMode() {
        return (
            <div className="flex flex-col">
                <div
                    className={`
                        grid w-full grid-cols-[30%_70%] items-center gap-y-4
                        pt-4
                    `}
                >
                    <label className={`text-md`}>Show advanced settings</label>
                    <Checkbox
                        checked={showAdvanced}
                        onChange={() => setShowAdvanced(!showAdvanced)}
                    />
                </div>
                {showAdvanced && (
                    <div
                        className={`
                            grid w-full grid-cols-[30%_70%] items-center gap-y-4
                            pt-4
                        `}
                    >
                        <hr className="col-span-full my-8 border-bonza-grey" />
                        <label className={`text-md`}>Diffuse IR depth</label>
                        <DiffuseIRDepthSelector />
                        <label className={`text-md`}>Two-channel zone</label>
                        <TwoChannelZoneSelector />
                        <label className={`text-md`}>Direct on/off</label>
                        <DirectAudioCheckbox />
                        <hr className="col-span-full my-8 border-bonza-grey" />
                        {/* Old advanced options */}
                        <label className={`text-md`}>Device Sample Rate</label>
                        <GenericSelector
                            title="Device Sample Rate"
                            options={SampleRateValueStrings}
                            value={LocalDevice.deviceDataStore.advancedSettings.sampleRateIndex.toString()}
                            handleChange={(e) => {
                                console.log(
                                    `Selected sample rate index: ${e.target.value}`
                                )
                                LocalDevice.deviceDataStore.advancedSettings.sampleRateIndex =
                                    e.target.value.toString()
                            }}
                        />
                        <label className={`text-md`}>Network buffer</label>
                        <GenericSelector
                            title="Network buffer"
                            options={NetworkBufferSizeStrings}
                            value={
                                LocalDevice.deviceDataStore.advancedSettings
                                    .networkBufferSizeIndex
                            }
                            handleChange={(e) => {
                                console.log(
                                    `Selected network buffer index: ${e.target.value}`
                                )
                                LocalDevice.deviceDataStore.advancedSettings.networkBufferSizeIndex =
                                    e.target.value.toString()
                            }}
                        />
                        <label className={`text-md`}>Network Interface</label>
                        <GenericSelector
                            title="Network Interface"
                            options={LocalDevice.deviceDataStore.getaNICs()}
                            value={LocalDevice.deviceDataStore.advancedSettings.nicIndex?.toString()}
                            handleChange={(e) => {
                                if (
                                    LocalDevice.deviceDataStore.advancedSettings
                                        .nicIndex != e.target.value
                                ) {
                                    LocalDevice.deviceDataStore.advancedSettings.nicIndex =
                                        e.target.value.toString()
                                    LocalDevice.logger.warn(
                                        `New Selected NIC index: ${e.target.value} - must reconnect engine`
                                    )
                                } else {
                                    fNOP()
                                }
                            }}
                        />
                        <label className={`text-md`}>Codec</label>
                        {/* this uses an object not an array as an example of how to do it in a slightly different way */}
                        <GenericSelector
                            options={CodecOptionStrings}
                            value={LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex.toString()}
                            handleChange={(e) => {
                                console.log(`Selected codec: ${e.target.value}`)
                                LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex =
                                    Number(e.target.value).toString()
                                Agent.send(
                                    new setStreamQualityMessage(
                                        LocalDevice.deviceDataStore.advancedSettings.codecOptionsIndex
                                    )
                                )
                            }}
                        />
                        {/* Old output channel slector - unecessary unless you want to break things */}
                        <hr className="col-span-full my-8 border-bonza-grey" />
                        <label
                            className={`
                                text-md

                                ${outputDevice == null ? `text-bonza-grey` : ""}
                            `}
                        >
                            Output channels
                        </label>
                        <OutputChannelsSelector
                            disabled={outputDevice == null}
                            agent={Agent}
                            device={LocalDevice}
                            handleChange={handleSelectChange}
                            name="OutputChannelsSelector"
                        />
                        <hr className="col-span-full my-8 border-bonza-grey" />
                        <label
                            className={`
                                text-md

                                ${outputDevice == null ? `text-bonza-grey` : ""}
                            `}
                        >
                            Developer Mode
                        </label>
                        <Checkbox
                            checked={developerMode}
                            onChange={() => setDeveloperMode(!developerMode)}
                        />
                    </div>
                )}
                {navigator.userAgent.toLowerCase().indexOf("windows") > -1 ? (
                    <>
                        <hr className="my-8 border-bonza-grey" />
                        <PrimaryButton
                            disabled={false}
                            onClick={asioClickHandler}
                        >
                            ASIO
                        </PrimaryButton>
                    </>
                ) : null}
            </div>
        )
    }
}
