import { LocalRemoteManager, SJ_USERID_OFFSET } from "@/services/BonzaService"
import { fNOP } from "./Device"
import { SliderMinMax } from "@/components/AudioControlLocal"
import { defaultBwIndex } from "@/components/settingsSelectors/VideoColourSelector"
import { defaultResIndex } from "@/components/settingsSelectors/VideoResolutionSelector"

/**
 * Represents a Bonza application message.
 * @interface
 * @property {string} type - The type of the message.
 */
export interface AppMessageModel {
    type: string
}

/**
 * Most basic implementation of AgentService message, needs extending to be any use.
 * @implements {AppMessageModel}
 */
export abstract class AgentMessage implements AppMessageModel {
    public readonly type: string

    constructor(type: string) {
        this.type = type
    }

    /**
     * Returns a JSON string representation of the object.
     *
     * @return {string} A string representation of the object.
     */
    public toString(): string {
        return JSON.stringify(this)
    }
}

/**
 * Most basic implementation of BonzaService message, needs extending to be any use.
 * @implements {AppMessageModel}
 */ export abstract class BonzaMessage implements AppMessageModel {
    public readonly type: string

    constructor(type: string) {
        this.type = type
    }

    /**
     * Returns a JSON string representation of the object.
     *
     * @return {string} A string representation of the object.
     */
    public toString(): string {
        return JSON.stringify(this)
    }
}

/// *** Agent messges ie to local core engine BonzaApp ***

/**
 * A class representing a basic audio set value message
 *  local audio vol or pan.
 */
export abstract class SetLocalAudioMessageBase extends AgentMessage {
    public readonly channel: number
    public readonly level01: number

    public constructor(type: string, channel: number, level: number) {
        super(type)
        this.channel = channel
        this.level01 = level
    }

    /**
     * Converts the current object to a JSON representation.
     *
     * @return {Object} The JSON representation of the object.
     */
    public toJSON(): object {
        const data: { [key: string]: any } = {
            type: this.type,
            chan: this.channel,
            level: this.level01,
        }
        return data
    }
}

export class SetLocalVolumeV240124Message extends SetLocalAudioMessageBase {
    public constructor(channel: number, level: number) {
        super("setLocalVolumeV240124", channel, level)
    }
}

export class SetLocalPanV240124Message extends SetLocalAudioMessageBase {
    public constructor(channel: number, level: number) {
        super("setLocalPanV240124", channel, level)
    }
}

// ordinary LocalVol and pan with chan as -1 would be for a master LOCAL ONLY vol/pan in BA
// export class SetMasterLocalVolV240124Message extends SetLocalAudioMessageBase {
//     public constructor(channel: number, level: number) { // channel N/U
//         super("masterLocalVolV240124",channel,level);
//     }
// }

// export class SetMasterLocalPanV240124Message extends SetLocalAudioMessageBase {
//     public constructor(channel: number, level: number) { // channel N/U
//         super("masterLocalPanV240124",channel,level);
//     }
// }

export class SetMasterVolV240124Message extends SetLocalAudioMessageBase {
    public constructor(level: number) {
        super("masterVolV240124", -1, level)
    }
}

export class SetMasterPanV240124Message extends SetLocalAudioMessageBase {
    public constructor(level: number) {
        super("masterPanV240124", -1, level)
    }
}

// (remote messages semi OK already (but use string rep of float 0:1 )
// - no need to make special versions for 240116+ compat)
// BUT SetRemoteVolumeMessage expects float range 0:1 as string
export class SetRemoteVolumeMessage extends AgentMessage {
    public id: string
    public level: string
    public constructor(id: string, level: string) {
        super("setRemoteVolume")
        this.id = id
        this.level = level
    }

    public toJSON(): object {
        const data: { [key: string]: any } = {
            type: this.type,
            ID: this.id,
            level: this.level,
        }
        return data
    }
}

// BUT SetRemotePanMessage expects number range -100:100 (integer not float) as string
// caller handles this...
export class SetRemotePanMessage extends AgentMessage {
    public id: string
    public pan: string
    public constructor(id: string, pan: string) {
        super("setRemotePanning")
        this.id = id
        this.pan = pan
    }

    public toJSON(): object {
        const data: { [key: string]: any } = {
            type: this.type,
            ID: this.id,
            pan: this.pan,
        }
        return data
    }
}

export class SetRemoteAzimuthMessage extends AgentMessage {
    public readonly id: string
    public readonly azimuth: number
    public readonly elevation: number = 0
    public readonly distance: number

    public constructor(id: string, azimuth: number, distance: number) {
        super("setRemoteAzimuthAndDistance")
        this.id = id
        this.azimuth = azimuth
        this.distance = distance
    }

    /**
     * Converts the current object to a JSON representation.
     *
     * @return {Object} The JSON representation of the object.
     */
    public toJSON(): object {
        const data: { [key: string]: any } = {
            type: this.type,
            ID: this.id,
            azimuth: this.azimuth,
            elevation: this.elevation,
            distance: this.distance,
        }
        return data
    }
}

/**
 * Represents a getSettingsV240124 message.
 * @extends AgentMessage
 * Expects response: applySettingsV240124
 */
export class getSettingsV240124Message extends AgentMessage {
    constructor() {
        super("getSettingsV240124")
    }
}

/**
 * Represents a standalone message.
 * @extends AgentMessage
 * Expects response: standalone [ with version to check]
 */
export class standaloneMessage extends AgentMessage {
    // default for now
    public readonly mode: string = "public" // or "private" - looks like private not supported? TBC
    public readonly username: string = ""
    constructor(mode: string, username: string) {
        super("standalone")
        this.mode = mode
        this.username = username
    }
}

export class getUUIDMessage extends AgentMessage {
    constructor() {
        super("getUUID")
    }
}

/**
 * Represents a probe message.
 * @extends AgentMessage
 *
 */
export class ProbeMessage extends AgentMessage {
    constructor() {
        super("probe")
    }
}

/**
 * Represents a stopAudioEngine message.
 * @extends AgentMessage
 */
export class stopAudioEngine extends AgentMessage {
    constructor() {
        super("stopAudioEngine")
    }
}

/**
 * Represents a StartAudio message. (more params to be supplied after checking...)
 * @extends AgentMessage
 * PREREQUISITES: must have previously sent a probe message which allows us to know the s/c details in & out.
 * User should choose input card and output card.
 */
export class StartAudioMessageWithMostParams extends AgentMessage {
    public readonly inputIndex: string
    public readonly outputIndex: string
    public readonly audioChannelIndex: string // 0=mono, 1=mix, 2=stereo, 3=4chans, 4=8chans.
    public readonly frameSize: string = "256"
    public readonly bitDepth: string = "16"
    public readonly sampleRate: string = "48000"
    public readonly frameSizeSend: string = "256" // frameSizeSend can be >= but not < frameSize
    public readonly playbackChannels: string // actual not coded
    public readonly NBUCHSEN: number = 16
    public readonly buchsen: Array<string>

    constructor(
        inputIndexS: string,
        outputIndexS: string,
        audioChannelIndexS: string,
        playbackChannelsS: string,
        frameSizeS: string,
        sampleRateS: string | undefined,
        frameSizeSendS: string | undefined
    ) {
        super("startAudioEngine")
        this.inputIndex = inputIndexS
        this.outputIndex = outputIndexS
        this.audioChannelIndex = audioChannelIndexS
        let numInChans = 0
        if (audioChannelIndexS == "0") {
            numInChans = inputChannelCoundPossibilitiesReverseMap[0]
        } else {
            const inChanIndex: number = parseInt(audioChannelIndexS)
            if (
                inChanIndex >= 1 &&
                inChanIndex < inputChannelCoundPossibilitiesReverseMap.length
            ) {
                numInChans =
                    inputChannelCoundPossibilitiesReverseMap[inChanIndex]
            } else {
                numInChans = 0
                // if fails?
            }
        }
        this.playbackChannels = playbackChannelsS
        this.frameSize = frameSizeS

        const tmp: number = Number(frameSizeSendS)
        if (tmp < Number(frameSizeS)) {
            this.frameSizeSend = frameSizeS
        } else {
            this.frameSizeSend = tmp.toString()
        }

        this.sampleRate =
            sampleRateS != undefined
                ? sampleRateS
                : SampleRateValueStrings[defaultSampleRateIndex]

        this.buchsen = new Array<string>(this.NBUCHSEN)
        for (let i = 1; i <= this.NBUCHSEN; i++) {
            if (numInChans >= i) {
                this.buchsen[i - 1] = "on"
            } else {
                this.buchsen[i - 1] = "off"
            }
        }
    }

    public toJSON(): object {
        const data: { [key: string]: string } = {
            type: this.type,
            inputIndex: this.inputIndex,
            outputIndex: this.outputIndex,
            audioChannelIndex: this.audioChannelIndex,
            frameSize: this.frameSize,
            bitDepth: this.bitDepth,
            sampleRate: this.sampleRate,
            frameSizeSend: this.frameSizeSend,
            playbackChannels: this.playbackChannels,
            buchse1: this.buchsen[0],
            buchse2: this.buchsen[1],
            buchse3: this.buchsen[2],
            buchse4: this.buchsen[3],
            buchse5: this.buchsen[4],
            buchse6: this.buchsen[5],
            buchse7: this.buchsen[6],
            buchse8: this.buchsen[7],
            buchse9: this.buchsen[8],
            buchse10: this.buchsen[9],
            buchse11: this.buchsen[10],
            buchse12: this.buchsen[11],
            buchse13: this.buchsen[12],
            buchse14: this.buchsen[13],
            buchse15: this.buchsen[14],
            buchse16: this.buchsen[15],
        }
        return data
    }
}

/**
 * Represents a setVideoDevice message.
 * @extends AgentMessage
 * This actually toggles the device if re-sent
 * Or if different to previous, turns old one off & new one on.
 */
export class setVideoDeviceMessage extends AgentMessage {
    public readonly index: string
    constructor(index: string) {
        super("setVideoDevice")
        this.index = index
    }
}

/**
 * Represents a setScreenSharing message.
 * @extends AgentMessage
 */
export class setScreenSharingMessage extends AgentMessage {
    public readonly screenSharingValue: "YES" | "NO"
    constructor(screenSharingValue: boolean) {
        super("setScreenSharing")
        this.screenSharingValue = screenSharingValue ? "YES" : "NO"
    }
}

/**
 * Represents a saveSettingsToFile message.
 * @extends AgentMessage
 */
export class saveSettingsToFileMessage extends AgentMessage {
    // OLD VER DO NOT EDIT!
    // all defaults for now!!!
    public readonly userName: string = ""
    public readonly soundInIndex: string = "0"
    public readonly soundOutIndex: string = "0"
    public readonly frameSize: string = "2"
    public readonly inChannels: string = "2"
    public readonly localVolume: string = SliderMinMax.default.toString()
    constructor() {
        super("saveSettingsToFile")
    }
}

export class saveSettingsToFileV240124Message extends AgentMessage {
    public readonly settingsDataV240124JSON: string
    constructor(settings: settingsDataV240124) {
        super("saveSettingsToFileV240124")
        this.settingsDataV240124JSON = JSON.stringify(settings)
        fNOP()
    }
}

/**
 * Represents a getExternalIPAndPort message.
 * @extends AgentMessage
 * This needs to be automatically sent after 1s then every 5s
 * Expects response: "crashIndicator"
 */
export class getExternalIPAndPortMessage extends AgentMessage {
    public readonly serverPort: string = "50000"
    constructor() {
        super("getExternalIPAndPort")
    }
}

/* Represents a setInterface message.
 * @extends AgentMessage
 * ( in engine this is not used other than gets sent back to us
 *  in the "tellPort" message as "interfaceIP" ) */
export class setInterfaceMessage extends AgentMessage {
    public readonly IF: string
    constructor(IF: string) {
        super("setInterface")
        this.IF = IF
    }
}

/// misc poss values
export const latencies = [64, 128, 256, 512]
export const latencyStrings = [
    "lowest (64)",
    "low (128)",
    "moderate (256)",
    "fair (512)",
]
export const defaultLatenciesIndex = 1 // 128
export const inputChannelCountPossibilities = [1, 2, 4] // !!! for Demo only allow 4 max
export const inputChannelCountPossibilityIndices = [0, 2, 3]
export const inputChannelCoundPossibilitiesReverseMap = [1, 1, 2, 4]
export const outputChannelCountPossibilities = [1, 2, 4, 8, 16] // KB 250108 mod for 16
export const outputChannelCountPossibilitiesReverseMap = [
    undefined, // 0
    0, // 1
    1, // 2
    undefined, // 3
    2, // 4
    undefined, // 5
    undefined, // 6
    undefined, // 7
    3, // 8
    undefined, // 9
    undefined, //
    undefined, //
    undefined, //
    undefined, //
    undefined, //
    undefined, // 15
    4,
]
export const defaultOutputChannelsActual: number = 2
export const defaultOutputChannelsIndex: number = 1 // index 1 == 2 chans

// LDD:advancedMode values etc
export const SampleRateValueStrings = ["48KHz", "96KHz", "192KHz"]
export const SampleRateValues = [48000, 96000, 192000]
export const defaultSampleRateIndex = 0

export const NetworkBufferSizes = [64, 128, 256, 512]
export const NetworkBufferSizeStrings = ["64", "128", "256", "512"]
export const defaultNetworkBufferSizeIndex = 1 // nwk must be >= audio - see Device.ts:784 for check

// export const codecOptions = {
//     "opus24": "OPUS 24kbps",
//     "opus48": "OPUS 48kbps",
//     "opus96": "OPUS 96kbps",
//     "opus192": "OPUS 192kbps",
//     "linear768": "Linear 768kbps",
// };
// export const defaultCodecOptionsName = "opus192";

export const CodecOptionStrings = [
    "OPUS 24kbps",
    "OPUS 48kbps",
    "OPUS 96kbps",
    "OPUS 192kbps",
    "Linear 768kbps",
]
export const defaultCodecOptionsIndex = 2 // 96K

export const ManualJitterValueStrings = [
    "auto",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
] as const

export type ManualJitterOption = (typeof ManualJitterValueStrings)[number]

export const defaultmanualJitterIndex = 0 // auto
export const defaultNICIndex = 0

export class advancedSettingsDevZZZ {
    public sampleRateIndex: string = defaultSampleRateIndex.toString()
    public networkBufferSizeIndex: string =
        defaultNetworkBufferSizeIndex.toString()
    public codecOptionsIndex: string = defaultCodecOptionsIndex.toString()
    public manualJitterIndex: string = defaultmanualJitterIndex.toString()
    public nicIndex: string | undefined = undefined
}

/*
    /// SET STREAM QUALITY
    if (type == "setStreamQuality") {
        string streamQuality = obj["index"].toString().toUtf8().constData();)
*/
/* Represents a setStreamQuality message.
 * @extends Agent
 */
export class setStreamQualityMessage extends AgentMessage {
    public readonly index: string
    constructor(index: string) {
        super("setStreamQuality")
        this.index = index
    }
}

/// SPATIAL

/* Represents a setOzone message.
 * @extends AgentMessage
 */
//const ozoneMessagesOLD = ["zone A", "zone B", "zone C"]
export const ozoneMessages = ["zone A", "zone B", "zone C", "zone W"]
export class setOzoneMessage extends AgentMessage {
    public readonly ozone1: string = ozoneMessages[1]
    constructor(ozone1: string) {
        super("setOzone")
        this.ozone1 = ozone1
    }
}

/* Represents a diffuseIRDepth message.
 * @extends AgentMessage
 * "diffuse IR Depth 1" to "diffuse IR Depth 5", or "mute locals"
 */
export class diffuseIRDepthMessage extends AgentMessage {
    public readonly diffuseIRDepth: string = "diffuse IR Depth 1"
    constructor(diffuseIRDepth: string) {
        super("diffuseIRDepth")
        this.diffuseIRDepth = diffuseIRDepth
    }
}

/* Represents a diffuseIRDepthV240124 message.
 * @extends AgentMessage
 * 0-5 where 0-4 are levels, & 5 == mute locals
 */
export class diffuseIRDepthMessageV240124 extends AgentMessage {
    public readonly diffuseIRDepthi: number = 0
    constructor(diffuseIRDepthi: number) {
        super("diffuseIRDepthV240124")
        this.diffuseIRDepthi = diffuseIRDepthi
    }
}

// enum spatMode : int { spatUNSET = 0, spat180, spat360V, spat360A, spatNValidModes = 3 };
export enum spatModes {
    spatUNSET = 0,
    spat180,
    spat360V,
    spat360A,
}

export function getSpatialLocationsTable(developer?: boolean) {
    const envIsCoStar = import.meta.env.VITE_BONZA_COSTARDEMO === "true"
    if (envIsCoStar) return spatialLocationsTableCoStar
    if (developer) return spatialLocationsTableStd
    return spatialLocationsTableStd.filter((loc) => !loc.devOnly)
}

export type SpatialLocationProps = {
    message: string
    spatMode: spatModes
    display: string
    description?: string
    devOnly?: boolean
    imgName?: string
}

/*
EnlightenedHall
LiveRoomTight
Moorlands
OrchestrationHall
Stairwell
VanRear
*/
const spatialLocationsTableCoStar: Array<SpatialLocationProps> = [
    { message: "none", spatMode: spatModes.spatUNSET, display: "none" },
    // CoSTAR 360V (6) - use orig messages - bonza will use different IRs if in COSTARDEMO mode && s/c >= 16 op chans
    {
        message: "Gen6_Studio_360V",
        spatMode: spatModes.spat360V,
        display: "EnlightenedHall",
    },
    {
        message: "Treble_10x12x7_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "LiveRoomTight",
    },
    {
        message: "Treble_19x12x7_Church_360V",
        spatMode: spatModes.spat360V,
        display: "Moorlands",
    },
    {
        message: "Treble_40x13x9_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "OrchestrationHall",
    },
    {
        message: "Treble_37x18x14_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "Stairwell",
    },
    {
        message: "Treble_65x65x21_Venue_360V",
        spatMode: spatModes.spat360V,
        display: "VanRear",
    },
]

const spatialLocationsTableStd: Array<SpatialLocationProps> = [
    {
        message: "none",
        spatMode: spatModes.spatUNSET,
        display: "No room sound",
        description: "Choose another room sound to enable spatial audio",
    },
    // 180 (8)
    {
        message: "Suffolk_180",
        spatMode: spatModes.spat180,
        display: "Suffolk",
        description: "School hall",
    },
    {
        message: "Reading_180",
        spatMode: spatModes.spat180,
        display: "Reading",
        description: "School music room",
    },
    {
        message: "Beehive_180",
        spatMode: spatModes.spat180,
        display: "North London",
        description: "Spacious recording studio",
    },
    {
        message: "Area_88_180",
        spatMode: spatModes.spat180,
        display: "Area88",
        description: "Sound-treated production studio",
    },
    {
        message: "AudioLab_TeachingRoom_180",
        spatMode: spatModes.spat180,
        display: "G6 Teaching Room",
        devOnly: true,
    },
    {
        message: "Hes_Church_180",
        spatMode: spatModes.spat180,
        display: "Heslington Church",
        devOnly: true,
        imgName: "hes_church",
    },
    {
        message: "Hope_Ruin_180",
        spatMode: spatModes.spat180,
        display: "Hope & Ruin",
        devOnly: true,
        imgName: "hope_ruin",
    },
    {
        message: "Voodoo_Daddys_180",
        spatMode: spatModes.spat180,
        display: "Voodoo Daddy's",
        devOnly: true,
        imgName: "voodoo_daddys",
    },
    // 360V (11)
    {
        message: "Gen6_Studio_360V",
        spatMode: spatModes.spat360V,
        display: "G6 Studio",
        devOnly: true,
    },
    {
        message: "Treble_10x12x7_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "sim Theatre (small)",
        devOnly: true,
    },
    {
        message: "Treble_19x12x7_Church_360V",
        spatMode: spatModes.spat360V,
        display: "sim Church",
        devOnly: true,
    },
    {
        message: "Treble_40x13x9_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "sim Theatre (medium)",
        devOnly: true,
    },
    {
        message: "Treble_37x18x14_Theatre_360V",
        spatMode: spatModes.spat360V,
        display: "sim Theatre (large)",
        devOnly: true,
    },
    {
        message: "Treble_65x65x21_Venue_360V",
        spatMode: spatModes.spat360V,
        display: "sim Venue",
        devOnly: true,
    },
    {
        message: "Hes_Church_360V",
        spatMode: spatModes.spat360V,
        display: "Heslington Church",
        imgName: "hes_church",
        description: "Parish church",
    },
    {
        message: "Hope_Ruin_360V",
        spatMode: spatModes.spat360V,
        display: "Hope & Ruin (floor)",
        devOnly: true,
        imgName: "hope_ruin",
    },
    {
        message: "Hope_Ruin_Stage_360V",
        spatMode: spatModes.spat360V,
        display: "Hope & Ruin (stage)",
        imgName: "hope_ruin",
        description: "Small local venue",
    },
    {
        message: "Voodoo_Daddys_360V",
        spatMode: spatModes.spat360V,
        display: "Voodoo Daddy's",
        devOnly: true,
        imgName: "voodoo_daddys",
    },
    {
        message: "Voodoo_Daddys_Stage_360V",
        spatMode: spatModes.spat360V,
        display: "Voodoo Daddy's (stage)",
        imgName: "voodoo_daddys_stage",
        description: "Warm underground venue",
    },
    // len 20 (1+8+11 inc none) post 250228
]

export class spatialLocationMessage extends AgentMessage {
    public readonly spatialLocation: string
    public readonly spatMode: spatModes = spatModes.spatUNSET
    // above WIP
    constructor(spatialLocation: string) {
        super("spatialLocation")
        this.spatialLocation = spatialLocation
        const currTable = getSpatialLocationsTable()
        const index: number = currTable.findIndex(
            (s) => s.message === spatialLocation
        )
        if (index != -1) {
            this.spatMode = currTable[index].spatMode
        }
    }
}

export class inputDeviceSelectMessageV240124 extends AgentMessage {
    public readonly index: number = 0
    constructor(index: number) {
        super("inputDeviceSelectV240124")
        this.index = index
    }
}

export class outputDeviceSelectMessageV240124 extends AgentMessage {
    public readonly index: number = 0
    constructor(index: number) {
        super("outputDeviceSelectV240124")
        this.index = index
    }
}

/* Represents an openASIOPanelMessage message.
 * @extends AgentMessage
 */
export class openASIOPanelMessage extends AgentMessage {
    public readonly indexin: number
    public readonly indexout: number
    constructor(indexin: number, indexout: number) {
        super("openASIOPanel")
        this.indexin = indexin
        this.indexout = indexout
    }
}

/// REMOTE STREAM MESSAGES to AGENT

/*
    if (type == "startStream") {
        string IPAddress = obj["IP"].toString().toUtf8().constData();
        string port = obj["port"].toString().toUtf8().constData();
        string ownID = obj["ownID"].toString().toUtf8().constData();
        string remoteID = obj["remoteSenderID"].toString().toUtf8().constData();
        string remoteNAT = obj["remoteNAT"].toString().toUtf8().constData();
        string remoteSocketIP = obj["remoteSocketIP"].toString().toUtf8().constData();
        string remotePortTCP = obj["remotePortTCP"].toString().toUtf8().constData();
        string currentGroupPosition = obj["currentGroupPosition"].toString().toUtf8().constData();

        Localhost:
WS-SERVER MESSAGE RECEIVED: {"type":"startStream","IP":"127.0.0.1","port":"50050","ownID":"3",
"remoteSenderID":"0","remoteNAT":"50050 (111)","remoteSocketIP":"127.0.0.1","remotePortTCP":"50050"}

Actual remote:
WS-SERVER MESSAGE RECEIVED: {"type":"startStream","IP":"192.168.0.173","port":50050,"ownID":"3",
"remoteSenderID":"6","remoteNAT":"50050 (181)","remoteSocketIP":"82.41.0.201","remotePortTCP":"53664"}


*/

export interface startStreamParams {
    // valid remote data OR dont pass this for localhost (use StartStreamMessage defaults)
    IP: string // internal
    port: string
    ownID: number // when use convert to string
    remoteSenderID: number // when use convert to string
    remoteNAT: string // eg "50050 (181)"
    remoteSocketIP: string // external eg 82.41.0.201
    remotePortTCP: string // eg '53664'
    currentGroupPosition: number // probably the index in the list of remotes? (self = 0)
}
// hmm - this sorta needs to be a class not an interface so can set defaults & create 'empty'
// ones in our cache or in the database... its really the definition of some of the data held about a REMOTE.
// TODO: SUSS THIS.

export const LoopbackIP: string = "127.0.0.1"
export const LoopbackPort: string = "50050"
export const LoopbackPortwNAT: string = "50050 (111)"

/* Represents RemoteInfoData held about each remote...
 * TODO: merge into RemoteInfo class !!!
 */
export class RemoteInfoData implements startStreamParams {
    // default startStreamParams implementation is fake *localhost* ...
    // however number items that are usually strings here are actual numbers (get converted later)
    public IP: string = LoopbackIP
    public port: string = LoopbackPort
    public ownID: number = 3
    // re above 3: sorta may need a sep local class to hold these...???
    // to ask Alex if they would be the same per remote???
    public remoteSenderID: number = 0
    public remoteNAT: string = LoopbackPortwNAT
    public remoteSocketIP: string = LoopbackIP
    public remotePortTCP: string = LoopbackPort
    public currentGroupPosition: number = 0
    // eo startStreamParams implementation

    // other housekeeping params go here as necc...

    constructor() {
        fNOP()
    }

    /* not currenlty used...
    public setStartParams( params : startStreamParams ) {
        this.IP = params.IP;
        this.port = params.port;
        this.ownID = params.ownID;
        this.remoteSenderID = params.remoteSenderID;
        this.remoteNAT = params.remoteNAT;
        this.remoteSocketIP = params.remoteSocketIP;
        this.remotePortTCP = params.remotePortTCP;
        this.currentGroupPosition = params.currentGroupPosition
    }
    // or just set each member individully as necc (if not default)...
    */
}

export const MirrorIP: string = "144.76.81.210"
export const MirrorPort: string = "5401"
export const MirrorPortwNAT: string = "5401(111)"

// export function isLorM(IP: string) : boolean {
//     if (IP === LoopbackIP || IP == MirrorIP ) {
//         return true;
//     } else {
//         return false;
//     }
// } KB 240522 - for now disable 'special' mirror handling

export function isLname(name: string): boolean {
    if (name == "l" || name == "localhost") {
        return true
    }
    return false
}
export function isMname(name: string): boolean {
    if (name == "m" || name == "mirror") {
        //return true;
        return false // KB 240522 - since AC implemented BonzaMirror - we now cant trap its IP and handle specially
        // because that IP is no longer special - hence need to DISABLE the spacial handling of 'm' & 'mirror'
        // (for now - but poss for ever - not clear how to distinguish IFF get a message for just that IP...)
    }
    return false
}

export function isLorMname(name: string): boolean {
    if (isLname(name) || isMname(name)) {
        return true
    }
    return false
}

export class MirrorLH_SSP implements startStreamParams {
    public readonly IP: string = MirrorIP // server mirror
    public readonly port: string = MirrorPort
    public readonly ownID: number = 3
    public readonly remoteSenderID: number = 0
    public readonly remoteNAT: string = MirrorPortwNAT
    public readonly remoteSocketIP: string = MirrorIP // external
    public readonly remotePortTCP: string = MirrorPort
    public readonly currentGroupPosition: number = 0
    constructor() {}
}

/* Represents a startStream message.
 * @extends AgentMessage
 */
export class StartStreamMessage extends AgentMessage {
    // default params work for localhost ...
    public readonly IP: string = LoopbackIP // internal
    public readonly port: string = LoopbackPort
    public readonly ownID: string = "3"
    public readonly remoteSenderID: string = "0"
    public readonly remoteNAT: string = LoopbackPortwNAT
    public readonly remoteSocketIP: string = LoopbackIP // external
    public readonly remotePortTCP: string = LoopbackPort
    public readonly currentGroupPosition: string = "0"
    constructor(params?: startStreamParams) {
        super("startStream")
        if (params) {
            this.IP = params.IP
            this.port = params.port
            this.ownID = params.ownID.toString()
            this.remoteSenderID = params.remoteSenderID.toString()
            this.remoteNAT = params.remoteNAT
            this.remoteSocketIP = params.remoteSocketIP
            this.remotePortTCP = params.remotePortTCP
            this.currentGroupPosition = params.currentGroupPosition.toString()
        }
    }
}
// caller probably should also do a setRemoteVolume (...in SJ it arrives before the startStream???)
// but to do it properly would need to get the current value of the slider ???

/* Represents a stopStream message.
 * @extends AgentMessage
 */
export class stopStreamMessage extends AgentMessage {
    public readonly ID: string
    constructor(ID: string) {
        super("stopStream")
        this.ID = ID
    }
}

/* Represents a LocalDirectOnOffV240124 message.
 * @extends AgentMessage
 * param : boolean
 * Added 240123 - E.S. and spatial handling TODO!!!
 */
export class localDirectOnOffMessageV240124 extends AgentMessage {
    public readonly param: boolean
    constructor(param: boolean) {
        super("localDirectOnOffV240124")
        this.param = param
    }
}

/* Represents a fileHandler message.
 * @extends AgentMessage
 */
export class fileHandlerMessage extends AgentMessage {
    public readonly button: string
    constructor(button: string) {
        super("fileHandler")
        switch (button) {
            case "record":
            case "play":
            case "pause":
            case "stop":
                this.button = button
                break
            default:
                console.log(`fileHandlerMessage: bad button type ${button}`)
                this.button = ""
        }
    }
}

/* Represents a playTestSound message.
 * @extends AgentMessage
 * Added 240415 - 240105 done BA sj code-behind
 */
export enum playTestSoundMode {
    simultaneous = 0,
    left_then_right,
}

export class playTestSoundMessage extends AgentMessage {
    public readonly duration: number = 0.5 // seconds
    public readonly mode: number = playTestSoundMode.simultaneous
    constructor(duration: number, mode?: number | undefined) {
        super("playTestSound")
        this.duration = duration
        if (mode) {
            this.mode = mode
        }
    }
}

/* Represents a wideModeMessage.
 * @extends AgentMessage
 * Added 240501
 * see also "zone W" in twoChannelZoneSelector - which does the same thing via a new setOZone message
 */
export class wideModeMessage extends AgentMessage {
    public readonly boolVal: boolean = false
    constructor(boolVal: boolean) {
        super("wideMode")
        this.boolVal = boolVal
    }
}

/* Represents a flipSpatialLR message.
 * @extends Agent Message
 */
export class flipSpatialLRMessage extends AgentMessage {
    public readonly boolVal: boolean
    public readonly ID: string
    constructor(boolVal: boolean, ID: string) {
        super("flipSpatialLR")
        this.boolVal = boolVal
        this.ID = ID
    }
}

export class setVideoResolutionMessage extends AgentMessage {
    public readonly index: number
    constructor(index: number) {
        super("setVideoResolution")
        this.index = index
    }
}

export class setColorOrBWMessage extends AgentMessage {
    public readonly index: number
    constructor(index: number) {
        super("setColorOrBW")
        this.index = index
    }
}

/* Represents a setReceiverBufferSize message.
 * @extends Agent Message
 */
export class setReceiverBufferSizeMessage extends AgentMessage {
    public readonly ID: string
    public readonly value: string
    constructor(ID: string, value: string) {
        super("setReceiverBufferSize")
        this.ID = ID
        this.value = value
    }
}

/*
 * Represents a headTrackerZero message.
 */
export class headTrackerZeroMessage extends AgentMessage {
    constructor() {
        super("headTrackerZero")
    }
}

/// TEMPLATE (with one param)
/* Represents a ZZZ message.
 * @extends Agent OR Bonza Message - choose the correct one!
 */
export class ZZZMessage extends AgentMessage /* or BonzaMessage */ {
    public readonly param: string
    constructor(param: string) {
        super("ZZZ")
        this.param = param
    }
}

/// TEMPLATE
/* Represents a YYY message.
 * @extends Agent OR Bonza Message - choose the correct one!
 */
export class YYYMessage extends AgentMessage /* or BonzaMessage */ {
    constructor() {
        super("YYY")
    }
}

/// **** INCOMING AGENT MESSAGES [here for NOW!!!] ****
/*
    "standalone"
    version // should be "230616"

    "soundCardStatus"
    data1 // "YES" or "NO"

SJ:2361 240127
    sendObject["type"] = "sendVideoImage";
    sendObject["jpgBuffer"] = check;
SJNoS:712 240127
	if (msg.type == "sendVideoImage"){
		const imageDataUri = "data:image/jpeg;base64," + msg.jpgBuffer;
		videoImage.src = imageDataUri;
	}

// following 3 types get sent in response to a "probe" message
// and should be used to populate two lists of audio devices & video devices
// which the user can then choose from...
// at this stage I dont think NIC is used but TBA
// When the user selects a device its the audioCount/videoCount parameter thats used
// to identify which Device has been chosen.

    // note nonV1 messages all encode numbers etc as strings

	"setAudioDeviceInfo"
	 audioCount :      // index of this device (from 0)
     audioName :       // name
     inputChannels :   // num inputs
     outputChannels :  // num outputs

    "setVideoDeviceInfo"
	 videoCount :      // index of this device (from 0)
     videoName :       // device name or "no video" if none.

    "setNICOptions" // IFcount,IF1-6 <not sure what used for?>

    "applySettings" //
	 userName : string
	 audioIn :
	 audioOut :
     frameSizeIndex :
	 inChannels :
	 faderPos : // gLocalVolume NU @ pres

    "applySettingsV240124"
	 userName : string
	 audioIn :
	 audioOut :
     frameSizeIndex :
	 inChannels :
	 faderPos : // gLocalVolume NU @ pres
     // others TODO


    "setLocalSoundLevel" // ie VU meter levels
     channelCount : string   // num chans (eg "2")
     maxSampleValue : string  // `;` delimited string of floats eg "0;0.5"

    "setLocalSoundLevelV240124" // POSSIBLE NEW (@ 240327 still not done yet in BA)
     channel : number      // chan (eg 2)
     maxSampleValue : float  // float eg 0.5


*/

export class settingsDataOld {
    public userName: string = ""
    //public audioInCardIndex : string = ""; // post 240530 - unused
    //public audioOutCardIndex : string = ""; // post 240530 - unused
    public frameSizeIndex: string = defaultLatenciesIndex.toString()
    public inChannelsIndex: string = "0"
    public faderPos: string = "" // gLocalVolume NU @ pres
}

export class settingsDataV240124 extends settingsDataOld {
    /*
                          << outChannels << endl
                          << spatialLocation << endl
                          << diffuseIRLevel << endl
                          << oZone << endl;
*/
    public outChannelsActual: string = "2"
    public spatialLocationIndex: string = "0"
    public diffuseIRLevelIndex: string = "4"
    public oZoneIndex: string = "1"
    public directOnOff: string = "0" // convert to bool later

    // public videoCardIndex : string = "";
    public videoColorIndex: string = defaultBwIndex.toString()
    public videoResolutionIndex: string = defaultResIndex.toString()

    public audioInCardName: string | undefined = undefined
    public audioOutCardName: string | undefined = undefined
}

/* **** MESSAGES TO/FROM *BONZA* SERVER **** */

// DemoGUI to BonzaServer

/**
 * Represents a login message.
 * @extends BonzaMessage
 */
export class LoginMessage extends BonzaMessage {
    public readonly ownName: string
    public readonly ownSystemID: string = "0"

    constructor(ownName: string) {
        super("Login")
        this.ownName = ownName
    }
}

/* SJTG_addMessage (from GUI to Server)
 * @extends BonzaMessage
 * Tells the server who we are and the name of a potential remote we want to connect-to.
 * The server should respond with an "Add user" message back to ourselves with the remote details
 * (and sends similar message to our remote with our details)
 * - or if user of that name is not in its database - reponds with "SJTG_no_such_user"
 */
export class SJTG_addMessage extends BonzaMessage {
    public readonly remoteName: string
    public readonly ownName: string
    public readonly UDPPort1: string
    public readonly UDPPort2: string
    public readonly interfaceIP: string
    public readonly engineIP: string
    public readonly ID: string

    constructor(
        remoteName: string,
        ownName: string,
        UDPPort1: string,
        UDPPort2: string,
        interfaceIP: string,
        engineIP: string,
        ID: number
    ) {
        super("SJTG_add")
        this.remoteName = remoteName
        this.ownName = ownName
        this.UDPPort1 = UDPPort1
        this.UDPPort2 = UDPPort2
        this.interfaceIP = interfaceIP
        this.engineIP = engineIP
        if (ID < SJ_USERID_OFFSET) {
            ID = ID + SJ_USERID_OFFSET // 240426
        }
        this.ID = ID.toString()
    }
}

/*
    if (type == "KILL"){
        QString IPString = obj["IP"].toString();
        QString portTCP  = obj["portTCP"].toString();
*/
/* Represents a KILL message.
 * @extends BonzaMessage
 * This should cause the server to stop / delete any/all remotes connected to this IP and port
 * ULNS has a function to send this but it doesnt get called anywhere...
 * We may need this functionality if we start up & are already receiving stream(s) from a previous session
 * in which we aborted but the remotes are still active & sending to our IP and port!
 * Unclear how this would work though - to ask Alex...
 */
export class KILLMessage extends BonzaMessage {
    public readonly IP: string
    public readonly portTCP: string
    constructor(IP: string, portTCP: string) {
        super("KILL")
        this.IP = IP
        this.portTCP = portTCP
    }
}

/*
"Add User"
 - what to save via addEntry if no match
addEntry(
    msg.remoteName,
    msg.remoteIP,
    msg.remotePort,
    msg.remoteID,
    msg.remoteUDPPort //"N/A",
    0,0,0,3,0.4,0,0,0,
    msg.remoteUDPPort,
    0, "N/A",
    msg.remoteUDPPort2,
    msg.interfaceIP,
    "N/A", // @19 params here...
    "public","none",0,0,
    msg.engineIP
); // 24 params

// note msg.engineIP (param 24) seems to have "fallen off the end" of the poss params (19) for addEntry???

and then this is what gets pushed to local user array
/// WENN DIE DATENBANK AUSGELESEN WIRD ODER SOCKET NEUEN EINTRAG EMPFÄNGT, WIRD DIE LISTE UPGEDATED
function addEntry(
    valueName,
    valueIP,
    valuePort,
    valueID,
    valuePortUDP,valueRecQuality,valueRecChannels,valueRecFrameSize,valueBufferSize,valueVolume,valueConnected,loggiValue,faderValue,
    actualPortValue,
    currentSendPortValue,audioStatus,
    valuePortUDP2,
    valueInterfaceIP,
    valueOS){
        ?? 19 params?

 - update if already matched...
    userPort     = msg.remotePort;
    userIP       = msg.remoteIP;
    userID       = msg.remoteID;
    systemID     = 0;
    interfaceIP  = msg.interfaceIP;
    engineIP     = msg.engineIP;

        and an example message: (ones useful? (tbc) *)
            action: 'Add user'
            appStatus: 'YES'
            audioStatus: 'YES'
        *engineIP: '82.41.0.201'
            groupPosition: '25'
            index: 0
        *interfaceIP: '192.168.0.173'
            level: 65
            message: 'Userlist update'
        ?NAT: '181'
            OS: 'WIN'
            ownIP: '82.41.0.201'
            ownPort: '63208'
            password: 'none'
        *remoteID: '2'
        *remoteIP: '82.41.0.201'
            remoteName: 'Ken'
        *remotePort: '49975'
        ?remoteSystemID: '26447'
            remoteUDPPort: '50050'
            remoteUDPPort2: '50050 (181)'
            roomName: 'LabChor'
            type: 'system
*/

/* Represents a HEARTBEAT message.
 * @extends Bonza Message
 */
export class HEARTBEATMessage extends BonzaMessage {
    constructor() {
        super("HEARTBEAT")
    }
}

export class UDPPortUpdateMessage extends BonzaMessage {
    public readonly engineIP: string
    public readonly ownUDPPort: string
    public readonly ownUDPPort2: string
    public readonly NAT: string
    public readonly interfaceIP: string
    public readonly OS: string

    constructor(
        engineIP: string,
        ownUDPPort: string,
        ownUDPPort2: string,
        NAT: string,
        interfaceIP: string,
        OS: string
    ) {
        super("UDP-Port-Update")
        this.engineIP = engineIP
        this.ownUDPPort = ownUDPPort
        this.ownUDPPort2 = ownUDPPort2
        this.NAT = NAT
        this.interfaceIP = interfaceIP
        this.OS = OS
    }
}

export class ROOMMessage extends BonzaMessage {
    public readonly joomlaID: string = "0"
    public readonly roomName: string
    public readonly password: string
    public readonly userID: string
    constructor(roomName: string, password: string, userID: number) {
        super("ROOM")
        this.roomName = roomName
        this.password = password
        let sjUserID = userID
        if (userID < SJ_USERID_OFFSET) {
            LocalRemoteManager.logger.error(
                `ROOMMessage: userID was ${userID} - auto-fixing`
            )
            sjUserID = userID + SJ_USERID_OFFSET
        }
        this.userID = sjUserID.toString()
    }
}

// *** INCOMING BONZA MESSAGES - to allow checking

export interface Add_UserMessage {
    remotePort: string
    remoteIP: string
    remoteID: string
    interfaceIP: string
    engineIP: string
    remoteUDPPort: string
    remoteUDPPort2: string
    NAT: string
    remoteName: string
}
