import { useEffect, useState } from "react"
import MenuItem from "@mui/material/MenuItem"
import { inputChannelCountPossibilities } from "@/types/AppMessage"
import { SelectorExtraProps } from "@/types/DeviceSelector"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { checkOKToStart } from "../SelectMenu"
import { sendSaveSettings } from "@/types/Device"
import SelectInput from "../formInputs/SelectInput"

export default function InputChannelsSelector(props: SelectorExtraProps) {
    //    const [value, setValue] = useState(props.value ?? props.device.deviceDataStore.inChansSelectIndex);
    const [value, setValue] = useState(
        props.value ?? props.device.getSavedSettings().inChannelsIndex
    )
    const [disabledOptions, setDisabledOptions] = useState(new Array<boolean>())

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        updateIndex(JSON.parse(event.target.value))
    }

    const updateIndex = (index: number) => {
        setValue(index)

        //props.device.deviceDataStore.inChans = inputChannelCountPossibilities[index];
        //props.device.deviceDataStore.inChansSelectIndex = index;

        const settings = props.device.getSavedSettings()
        if (settings.inChannelsIndex != `${index}`) {
            settings.inChannelsIndex = `${index}`
            props.device.saveSavedSettings(settings)
            sendSaveSettings(Agent, settings)
        }

        if (checkOKToStart(props)) {
            const inhibRequestNewSettings: boolean = true
            props.device.sequence_Start(inhibRequestNewSettings)
        }

        if (props.handleChange)
            props.handleChange({
                target: {
                    name: props.name ?? "InputChannelsSelector",
                    value: value,
                },
            })
    }

    function LimitValue(inval: number | string | readonly string[]): string {
        const dev = LocalDevice
        const card = dev.activeInputSoundCard
        if (!card) {
            return "0"
        }
        const maxchans = card.inputChannels
        let valn = inval instanceof Array ? inval[0] : inval
        valn = Number(valn)
        while (valn > 0) {
            const thisnc = inputChannelCountPossibilities[valn]
            if (thisnc > maxchans) {
                --valn
                setValue(valn)
            } else {
                break
            }
        }
        return valn.toString()
    }

    let update = false
    const incard = LocalDevice.activeInputSoundCard
    const maxchans = incard?.inputChannels
    let maxIndex = 0
    inputChannelCountPossibilities.map((_, index) => {
        const disable =
            !incard ||
            !maxchans ||
            inputChannelCountPossibilities[index] > maxchans
        if (!disable) maxIndex = index
        update ||= disable !== disabledOptions[index]
        disabledOptions[index] = disable
    })
    if (update) {
        setDisabledOptions([...disabledOptions])
        if (maxIndex < +value) updateIndex(maxIndex)
    }

    useEffect(() => {
        if (props.handleChange) {
            props.handleChange({
                target: {
                    name: props.name ?? "InputChannelsSelector",
                    value: value,
                },
            })
        }
    }, [value, disabledOptions, props.disabled])

    return (
        <SelectInput
            value={`${LimitValue(value)}`}
            onChange={handleChange}
            disabled={props.disabled}
        >
            {inputChannelCountPossibilities.map((count, index) => (
                <option
                    key={index}
                    value={index}
                    disabled={disabledOptions[index]}
                >
                    {count}
                </option>
            ))}
        </SelectInput>
    )
}
