import { SelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import { Agent, LocalDevice } from "@/services/BonzaService"
import { ozoneMessages, setOzoneMessage } from "@/types/AppMessage"
import { sendSaveSettings } from "@/types/Device"
import SelectInput from "../formInputs/SelectInput"

const ZoneNames = ["A", "B", "C", "W"]
export default function TwoChannelZoneSelector(props: SelectorProps) {
    const [zoneIndex, setZoneIndex] = useState(
        parseInt(LocalDevice.getSavedSettings().oZoneIndex ?? "0")
    )

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const zoneIndex = parseInt(event.target.value)
        const settings = LocalDevice.getSavedSettings()
        if (settings.oZoneIndex != `${zoneIndex}`) {
            settings.oZoneIndex = `${zoneIndex}`
            LocalDevice.saveSavedSettings(settings)
            Agent.send(new setOzoneMessage(ozoneMessages[zoneIndex]))
            sendSaveSettings(Agent, settings)
        }
        setZoneIndex(zoneIndex)

        if (props.handleChange) props.handleChange(event)
    }

    const values = []
    for (const i in ZoneNames) {
        values.push(
            <option value={i} key={i}>
                Zone {ZoneNames[i]}
            </option>
        )
    }

    return (
        <SelectInput
            value={`${zoneIndex}`}
            disabled={props.disabled}
            onChange={(e) => handleChange(e)}
        >
            {values}
        </SelectInput>
    )
}
