import { GenericSelectorProps } from "@/types/DeviceSelector"
import { useState } from "react"
import SelectInput from "../formInputs/SelectInput"

export default function GenericSelector(props: GenericSelectorProps) {
    const options = []
    const [value, setValue] = useState<string>(props.value ?? "")
    for (const i in props.options) {
        options.push(
            <option value={i} key={i}>
                {props.options[i]}
            </option>
        )
    }

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLSelectElement>
            | {
                  target: {
                      name: string
                      value: string | number | readonly string[]
                  }
              }
    ) => {
        setValue(e.target.value as string)
        if (props.handleChange) props.handleChange(e)
    }

    return (
        <SelectInput
            title={props.title}
            value={value}
            disabled={props.disabled}
            onChange={handleChange}
        >
            {options}
        </SelectInput>
    )
}
