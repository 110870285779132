import { IDevice } from "@/types/Device"
import { SelectChangeEvent } from "@mui/material/Select"
import { ComponentProps } from "react"
import { IAgentService } from "@/services/AgentService"
import { WebSocketService } from "@/types/WebSocket"

export enum SoundCardIOType {
    Input,
    Output,
}

export interface SoundCardSelectorProps extends ComponentProps<"select"> {
    title?: string
    device: IDevice
    type?: SoundCardIOType
    className?: string
}

export interface SelectorProps extends ComponentProps<"select"> {
    handleChange?: (
        event:
            | SelectChangeEvent
            | {
                  target: {
                      name: string
                      value: string | number | readonly string[]
                  }
              }
    ) => void
}

export interface SelectorExtraProps extends SelectorProps {
    agent: IAgentService & WebSocketService
    device: IDevice
}

export interface GenericSelectorPropsObject {
    [key: string | number]: string | number
}

export interface GenericSelectorProps extends SelectorProps {
    options: Array<string | number> | GenericSelectorPropsObject
    value?: string
}

// export function isOkToStart(props: SelectorExtraProps | SoundCardSelectorProps) : boolean {
//     let ok = false;

//     if(props.device) {
//         if(props.device.activeInputSoundCard && props.device.activeOutputSoundCard) {
//             ok = true;
//         } else {
//             fNOP();
//         }
//     } else {
//         fNOP();
//     }

//     return ok;
// }

// export function isOkToStart( props: SoundCardSelectorProps ): boolean {
//     return checkOKToStart(props);
// }
